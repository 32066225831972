import { DOC_PROMPT_TEMPLATE_ID, FILE_PARSE_RESULT, MEMBERSHIP_ID } from '@/common/config';
import type { Presentation_Themes } from '@/components/PresentationView';
import { JSONContent } from '@tiptap/react';

/**所有的文件类型 */
export enum EFileType {
  IMG = 'img',
  DOC = 'doc',
  PDF = 'pdf',
  PPT = 'ppt',
  OUTLINE = 'outline',
  CSV = 'csv',
  SLIDES = 'slieds',
}

export interface IConversationSearch {
  id: string;
  type: string;
  name: string;
  section: string;
  isFile: boolean;
}

export interface kvMap<T> {
  [key: string]: T;
}

export interface ResponseType<T = object> {
  code: number;
  data: T;
  message: string;
  shortMessage?: string;
  at_trace_id?: string;
}

export interface PromiseResult<T> {
  status: 'fulfilled' | 'rejected';
  value?: T;
  reason?: any;
}

export interface MessageData {
  channel: Channel;
  message: MessageItem[];
  at_trace_id?: string;
}

export interface Rect {
  id: string;
  rect: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    width: number;
    height: number;
    pageNumber: number;
  };
  pageContent?: string;
}

export interface Coordinate {
  rects: Rect[];
  pageNumber: number;
}

export interface MultiFileCoordinate extends UploadFileResponseObject {
  docChunks: Coordinate[];
}

export interface TextEnrichItem {
  content: string;
  action: string;
  selected: boolean;
}

export interface TextEnrich {
  suggest?: TextEnrichItem;
  original?: TextEnrichItem;
}

export enum EMediaType {
  OUTLINE = 'OUTLINE',
  SLIDES = 'SLIDES',
}
export enum MessageStatusEnum {
  DEFAULT = 'DEFAULT',
  DOC_CARD_AI = 'DOC_CARD_AI',
  DOC_CARD = 'DOC_CARD',
}

export interface QuoteInfoItem {
  num: string;
  title: string;
  url: string;
  source: string;
  favicon: string;
  publishDate?: string;
  snippet?: string;
  isPdf?: boolean;
}

export interface MessageItem {
  fileParsed?: boolean;
  mediaInfo?: {
    mediaId?: number;
    title?: string;
    type?: EMediaType;
    channelAdvanceConfig?: Record<string, string>;
    inputContent?: string;
  };
  mediaInfoJson?: string;
  channelId?: string;
  content?: string;
  finalContent?: string;
  createdAt?: string;
  deleted?: boolean; //是否在数据库中标记为被删除，控制哪些消息不被前端展示。前端渲染时display: none
  id?: number;
  isDoc?: boolean; //前端没用到
  messageId?: string;
  relationId?: string; //关联性id，一对问答消息的关联id，answer消息关联question
  roleEnum?: string; //消息实体的类型：HUMAN，AI,FAKE (假消息，不是消息对的消息，例如消息里上传的文档标签)
  uid?: string;
  updatedAt?: string; //消息的更新时间
  improveId?: number; //如果消息是由boost产生的还是产生的，问题里带这个id，服务端用。（boost是先用模型优化pormpt再问答）
  richMessageId?: string; //点enrich条下的auto enrich full text生成的消息类型，问题里会带这个richMessageId
  action?: string; //动作，如翻译、划词
  feedback?: null | {
    //回复消息体下面的点赞，踩的按钮
    typeEnum: 'Like' | 'Dislike';
  };
  qaContent?: string;
  qaMsg?: string[]; //summary下面的问题提示
  urlInfo?: string; //输入问题如果带url的话会用上这个字段，在问题中对url做解析，提炼出来。在问题气泡中显示为urL组件。
  tip?: string; //后端返回的提示，例如等待消息等
  actions?: ActionItem[]; //消息下面的操作，如翻译、Enrich等。
  cursor?: boolean; //是否展示光标，纯前端使用
  processing?: boolean; //是否正在处理，纯前端
  error?: boolean; //是否出错，服务端会返回，前端也会构造
  errMsg?: string;
  coordinates?: string; //页码、坐标信息，PDF中的坐标信息，pdf场景中跳转到对应位置
  sources?: string;
  outline?: string;
  outlineMarkdown?: string; //双答案场景使用，在消息气泡里显示的outline内容。一直是markdown,不会变成json。
  slides?: string;
  outlineComplete?: boolean; //是否完成outline,完成的话从outline里取json。
  slidesComplete?: boolean; //是否完成slides所有内容的展示。
  imageUrls?: string[]; //图片模式时用到
  comparison?: boolean; // 是否多答案选择
  subMessageList?: MessageItem[];
  actionResult?: string; // 触发多答案的类型 MULTI_CHAT MULTI_SUMMARY MULTI_DOC_CHAT MULTI_PPT_OUTLINE MULTI_PPT_CONTENT
  pptCoordinates?: string; //PPT场景使用，划词时，被选中词所在的位置
  textEnrich?: TextEnrich; //PPT划词的文本
  chunkInfo?: { type?: string; content?: JSONContent[] | { nodeId: string; imageUrl: string }[] }; //PPT场景使用，流式输出的 part of json
  tempSlides?: JSONContent; //吐字场景用到的，吐字过程中用于展示
  chunkId?: string;
  at_trace_id?: string;
  messageMediaInfo?: MessageMediaInfo;
  model?: string; //message 的模型
  mediaType?: string; // SLIDES, OUTLINE
  msgStatus?: MessageStatusEnum;
  isLastMessage?: boolean;
  quoteInfoList?: QuoteInfoItem[]; // 搜索返回的引用列表
  intentName?: string; // 流式输出时的字段 搜索类型，分为普通搜索和文献搜索
  // messages 列表中的字段，搜索类型，分为普通搜索和文献搜索
  intentInfo?: {
    name: string;
    creative_intent?: boolean;
  };
  creativeIntent?: boolean;
  searchSwitch?: boolean; // 该条消息是否是联网状态下生成
  event?: 'startSuggestQuestion' | undefined;
  isFinish?: boolean; // 消息是否生成完
}

interface MessageMediaInfo {
  messageId?: string;
  title?: string;
  type?: string;
  chatTypeEnum?: string;
  docCardList?: MediaFile[];
  channelEntity?: ChannelEntity;
}

interface ChannelEntity {
  ext?: {
    channelTrace?: number;
    channelTypeEnum?: string;
    chatTypeEnum?: string;
    multiDoc?: boolean;
  };
  upload?: boolean;
}
export interface MediaFile {
  err_msg?: string;
  file_name: string;
  file_page_count: number;
  file_type: string;
  md5: string;
  isLastMessage?: boolean;
}

export interface ActionItem {
  name: string;
  value: string;
}

export interface UrlInfo {
  url?: string;
  mime?: string;
  downloadUrl?: string;
  size?: string;
}

export interface FeedbackConfig {
  chat?: {
    entags: string[];
    artags: string[];
  };
  docChat?: {
    entags: string[];
    artags: string[];
  };
}

export interface LandingPageInfo {
  aiCreationWorkshop: string;
  languageModelList: LanguageModel[];
  languageModelList4PC: LanguageModel[];
  languageModelList4PCNew: LanguageModel[];
  communityList: Category[];
  promptNavigation: PresetPrompt[];
  gpt4v: boolean;
  feedbackDislikeConfig?: FeedbackConfig;
}

export interface LanguageModel {
  name: string;
  disabled?: boolean;
  value?: string;
  isShow?: boolean;
  description?: string;
  role?: 'Chat' | 'DocChat';
  desc?: string;
}
//creation模板接口的相应数据
export interface PromptTemplateData {
  content: PromptTemplate[];
  totalElements: number;
  totalPages: number;
  number: number;
}

//卡片的数据结构
export interface PromptTemplate {
  createdAt?: number;
  deleted?: boolean;
  id?: number;
  mediaType?: string; //哪种数据类型，如ppt,image等
  model?: string; //预置的模型，GPT-3.5等
  realUseModel?: string;
  name?: string; // 标题
  prompt?: string;
  promptTemplateId?: string; // id
  authorName?: string;
  community?: string; // 分类，「all」tab里的分类
  updatedAt?: number;
  promptHint?: string; // 输入框 placeholder
  promptHintKey?: string; // 本地默认模版数据字段
  teaser?: string; // 描述
  collected?: boolean; // 收藏状态
  backgroundImage?: string;
  functionCall?: string; //底层走的哪些function call,联网、搜图等
  shareKey?: string;
  thumbnail?: string;
  newThumbnail?: string;
  newTip?: boolean;
  infoCollect?: boolean; //是否需要输入信息再触发创建，大部分都是，PPT可以不是
  themeName?: Presentation_Themes;
  hasTool?: boolean; //是否有工具
  usages?: number; //使用次数
  fuzzyUsages?: string; //使用次数
  connectionName?: string;
}

export interface DocEntries {
  multiDoc?: boolean; // 是否是多文档
  needUpgrade?: boolean; // 前端暂未使用
  docs?: UploadFileResponseObject[]; // doc数组
}

export enum ChannelTypeEnum {
  ONE_CHAT = 'ONE_CHAT',
  CHAT = 'CHAT',
  DOC = 'DOC',
  PPT = 'PPT',
}

export enum ChatTypeEnum {
  CHAT = 'Chat',
  DOC_CHAT = 'DocChat',
  AI_CREATION_PPT = 'AiCreationPpt',
  AI_CREATION = 'AiCreation',
  CHAT_IMAGE = 'ChatImage',
}
export interface ChannelExt {
  channelTrace?: 1 | 2 | 3;
  channelTypeEnum?: ChannelTypeEnum;
  chatTypeEnum?: ChatTypeEnum;
}
export interface Channel {
  searchSwitch?: boolean;
  multiDoc?: boolean;
  ext?: any;
  id?: string;
  channelId?: string;
  channelName?: string;
  model?: string;
  language?: string;
  modelName?: string; //可以暂时忽略，现在都用model。
  md5?: string; //跟文件上传绑定，文件的md5
  isDoc?: boolean;
  upload?: boolean; //是否是上传产生的channel，如上传pdf,doc
  promptTemplateId?: string; //如果channel是通过模板产生的，这里存对应模板的id。
  creatorUid?: string;
  deleted?: boolean;
  fromTpl?: boolean; //是否是从模板来的
  /**
   * 0：未完成
   * 1：PDF和其他文件状态不同
   *    1：PDF类型表示文件解析完，但是图片没解析完；
   *    2：其他类型表示解析完
   * 2：PDF里的图片解析完成
   * 3：PDF文本和图片都解析完
   */
  fileIsHandle?: FILE_PARSE_RESULT; //当前文件的解析状态。0：未完成，1：文本解析完成，2：抽图完成，3：抽图和文本解析都完成
  fileTypeEnum?: string | null; //文件类型,ppt,pdf,csv,doc,image等
  shareKey?: string; //是否是通过分享产生的channel。分享出去的url字段参数
  createdAt?: number;
  updatedAt?: number;
  errMsg?: string;
  errCode?: number;
  themeName?: Presentation_Themes;
  sources?: string;
  outline?: PPTSnapshot[];
  slides?: PPTSnapshot[];
  imageUrl?: string[];
  status?: number | null; // 1表示向量过期
  ppteditTime?: number; // PPT last 编辑时间
  flag?: string;
  thumbnailContent?: string;
  exceedNumber?: boolean;
  // #region 以下字段为解析图片的字段
  /**
   * 解析到的图片
   */
  hiresImages?: HiresImage[];
  /**
   * 解析图片的错误信息
   */
  hiresParseErrMsg?: string;
  /**
   * 解析图片的灰度用户
   */
  hiresIsGrayUser?: boolean;
  // #endregion
  docEntries?: DocEntries; // 多文档
  docMds?: {
    name: string;
    docId: string;
  }[]; // doc对话关联writing
  gdriveFileTypeEnum?: string; // 同 fileTypeEnum，主要用来区分文件来源
  pdfConvertStatus?: string; // word to pdf预览，格式转换是否完成
}

export interface LandingChannel {
  category: any;
  channel: Channel;
  channelId: string;
  files: { fileName: string; fileType: string }[];
  messageContent?: string;
}

export interface LandingChannel {
  category: any;
  channel: Channel;
  channelId: string;
  files: { fileName: string; fileType: string }[];
  messageContent?: string;
}

export interface ChannelImageList {
  hiresImages: HiresImage[];
  fileIsHandle: FILE_PARSE_RESULT; // 2 or 3表示当前批次抽图完成
  hiresParseErrMsg: string;
  hiresComplete: boolean; // 文档全部抽图完成
}

//channel里存的ppt。快照。
export interface PPTSnapshot {
  id?: number;
  channelId?: string;
  contentDsl?: string;
  content?: string;
  deleted?: boolean;
  createdAt?: number;
  updatedAt?: number;
  mediaType?: string;
  messageId?: string;
  status?: string;
  type?: string;
  relationId?: string;
}

export interface SourceItem {
  snippet: string;
  link: string;
}

export interface IProduct {
  id: number;
  createdAt: string;
  updatedAt: string;
  productId: string;
  price: number | string;
  currency: string;
  desc: string;
  membership: {
    membershipId: MEMBERSHIP_ID;
    gpt3Quota: number;
    gpt4Quota: number;
    uploadQuota: number;
    pdfPageQuota: number;
    chatDocQuota: number;
    improveQuota: number;
    writeMLongQuota?: number;
    writeLongQuota?: number;
    writeReportQuota?: number;
    writePaperQuota?: number;
    writeExportQuota?: number;
  };
  productIds: {
    [key: string]: string;
  };
  strikePrice?: number | string;
}

//「All」tab里的分类
export interface Category {
  communityTitle: string;
  communityId: string;
  communityName: string;
  communityLogo: string;
  communityDes: string;
  communityImg: string;
  recommend: boolean;
  order: number;
  communityLogoImage?: string; // 首页改版的icon
}

export interface PresetPrompt {
  title: string;
  content: string;
  logo: string;
}

export interface Language {
  langcode?: string;
  languageEnglish?: string;
  languageLabel?: string;
  groupName?: string;
  languages?: Language[];
  ocrShow?: boolean;
  ocrValue?: string[];
  languageAr?: string;
}

export interface IUsage {
  gpt3Usage: number;
  gpt3Quota: number;
  gpt4Usage: number;
  gpt4Quota: number;
  aiCreationQuota: number;
  aiCreationUsage: number;
  docUploadUsage: number;
  docUploadQuota: number;
  gpt3LimitHint: string;
  gpt4LimitHint: string;
  docUploadLimitHint: string;
  writeLongUsage: number;
  writeLongQuota: number;
  writePaperReportUsage: number;
  writePaperReportQuota: number;
  writeExtractUsage: number;
  writeExtractQuota: number;
  writeQuota: number;
  writeUsage: number;
  imageExtractUsage: number;
  imageExtractQuota: number;
  chatUsage: number;
  chatQuota: number;
  docUsage: number;
  docQuota: number;
}

export interface AiModelMap {
  [key: string]: {
    name: string;
    style: React.CSSProperties;
  };
}

//创建和获取channel的参数，接口用的都是getChannel，不传channelid就是新建。
export interface GetChannelParams {
  channelId?: string;
  generalChatChannelId?: string; //当前会话内的channelId
  templateId?: string; //从模板创建的时候，模板id
  message?: string; //消息框里问的问题
  model?: string;
  isUpload?: boolean; //是否有文件上传
  filename?: string;
  extname?: string;
  language?: string | null;
  imageUrls?: string[];
  fileType?: number | null;
  /**
   * 使用PDF创建PPT时，PDF的消息ID
   */
  pdfMsgId?: string;
  advanceConfig?: AdvanceConfig;
  generalChatGoPpt?: boolean; //是否是generalChatGoPpt
  searchSwitch?: boolean;
  channelType?: string;
  roleEnum?: string;
  docs?: UploadFileParamsObject[];
}

export interface SendOptions {
  /**默认打开文档预览 */
  defaultOpenGUI?: boolean;
  searchSwitch?: boolean;
  docs?: MediaFile[];
  gdrives?: GdriveFile[];
  message?: string;
  prompt?: string; //问题
  isNewChat?: boolean; //是否新建(创建chat时发的第一条消息为true)
  richMessageId?: string | null; //richcontent的messageId
  action?: string | null; //动作
  isGeneratePpt?: boolean; //是否是生成ppt的消息
  isSlidesChat?: boolean; //如果为true的话，后端对与正常对话就不处理（通常在ppt 的slide展示模式下使用）
  isPresetPpt?: boolean; //从预置模板进时，若这个参数是true，服务端直接返回模板对应的outline, source, slides（根据预置模板id获取，channel里带过去的）
  imageUrls?: string[]; //传图的场景使用
  imageFileNames?: string[]; //传图的场景使用
  fileType?: number | null;
  roleEnum?: string; //PDF场景中获取summary时用的，告诉服务端这个send要获取summary
  pptCoordinates?: string;
  outputLang?: string; //用户设置的输出语言
  advanceConfig?: AdvanceConfig;
  docPromptTemplateId?: DOC_PROMPT_TEMPLATE_ID;
  translateLanguage?: string | null; // 翻译使用
  /**file enhance 时解析的文件主语言 */
  language?: string | null;
  /**file enhance 时解析的文件md5 */
  md5?: string;
  /**file enhance时解析的文件名  */
  filename?: string;
  /**file enhance时解析的文件页数  */
  pageCount?: number;
  /**是否是file enhance */
  isUploadToEnhance?: boolean;
  /**file enhance的文件类型,目前只有ppt，未来可能有pdf,考虑是否弄成枚举 */
  extname?: string;
  isMultiDoc?: boolean; // 是否多文档
  enhanceText?: string; // 额外拼接的提示信息
  customAction?: string | null; // action 参数
  customMsgIds?: string[] | null; // 传入的msgID
  channelType?: string;
  messageList?: MessageItem[];
}

export interface AdvanceConfig {
  audience?: string;
  page?: string;
  wordAmount?: string;
  form?: string;
  pptReference?: string;
  pptTone?: string;
  pptModel?: string;
  language?: string | null;
  model?: string;
}
export interface CreateChannelOptions extends SendOptions {
  language?: string | null;
  advanceConfig?: AdvanceConfig;
}

interface GdriveFile {
  id: string;
  mimeType: string;
  name: string;
  url: string;
}
export interface CreateChatParams {
  model: string;
  md5?: string;
  url?: string;
  fileName?: string;
  fileContentType?: string;
  uploadTime?: number;
  /**
   * ocr语言
   */
  languages?: string[] | null;
  /**
   * 解析图片增加的参数，始终传true
   */
  extract_img_5k?: boolean;
  /**
   * 多文档
   */
  docs?: UploadFileParamsObject[];
  channelType?: string;
  message?: string;
  language?: string;
  channelId?: string;
  fromPpt?: boolean;
  gdrive?: GdriveFile;
  gdrives?: GdriveFile[];
  searchSwitch?: boolean;
}

export interface UploadFileParamsObject {
  md5?: string;
  fileName?: string;
  fileContentType?: string;
  uploadTime?: number;
}

export interface UploadFileResponseObject {
  md5?: string;
  fileName?: string;
  fileTypeEnum?: string;
  pageCount?: number;
  pdfConvertStatus?: string;
}

//获取分享页面(src/share/components/ShareView/index.tsx)的数据
export interface ShareDetailData {
  channelName: string;
  createdAt: number;
  updatedAt: number;
  fileTypeEnum: string;
  gdriveFileTypeEnum?: string;
  shareTypeEnum: string;
  model: string;
  picture: string;
  messageList: MessageItem[];
  themeName: string;
  slides: PPTSnapshot[];
  watermark: boolean;
  internal: number;
  multiDoc?: boolean; //是否是多文档
  doc?: {
    docId: string;
    name: string;
    content: string;
  };
  channel: Channel;
}

export interface DiscountCouponInfo {
  /** 前端不用这个字段 */
  allowed?: string;
  /** 活动名称 */
  desc?: string;
  /** @deprecated 折扣率 */
  discount: number;
  /** 优惠码 */
  discountCouponCode: string;
  /** 月折扣率 */
  monthlyDiscount?: number;
  /** 活动有效期 */
  period?: string;
  /** 升级按钮背景图片 */
  url?: string;
  /** 年折扣率 */
  yearlyDiscount?: number;
}

export interface DiscountInfo {
  discountCoupon: DiscountCouponInfo;
}

export interface CopyLinkData {
  shareKey: string;
}

export interface ThemeItem {
  themeName?: Presentation_Themes;
  themeBgUrl?: string;
}

export interface PresignedData {
  fields: {
    AWSAccessKeyId: string;
    key: string;
    policy: string;
    signature: string;
  };
  url: string;
}

export interface ExperimentUser {
  eid: string;
  uid: string;
  status: boolean;
  group: string;
  resultType: string;
  result: boolean;
}

export interface ExperimentUserState {
  eid: string;
  uid: string;
  state: string;
}

export interface Desc {
  label: string | { param: number | null | undefined; text: string };
  value: { param?: number | null | undefined; text: string }[] | null;
}
export interface ProductInfoItem {
  title: string | null;
  desc: (Desc | boolean)[];
}

export interface HiresImageCoordinates {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  /**
   * 图片宽度
   */
  width: number;
  /**
   * 图片高度
   */
  height: number;
}

export interface HiresImage {
  id: number;
  page_number: number;
  coordinates: HiresImageCoordinates;
  from_type: string;
  file_md5: string;
  file_url: string;
  file_name: string;
  /**
   * 当前页的宽度
   */
  page_width: number;
  /**
   * 当前页的高度
   */
  page_height: number;
}

export type HiresImageUrlAndName = Pick<HiresImage, 'file_url' | 'file_name'>;

export type UrlUploadResult = {
  extname: string; //'pdf';
  fileContentType: string; //'application/pdf';
  fileName: string; // '2401.16046';
  fileType: number; //20;
  md5: '203d3d40b89f340f03b6a899a9c08946';
  pageCount: number; // 25;
};

export type ImageChatWithType = HiresImageUrlAndName & {
  id?: number;
};

export interface TipsData {
  title: string;
  value: string;
  templateId?: number;
}

export interface DocTips {
  fileType: string;
  summary: TipsData[] | null;
  presentation: TipsData[] | null;
  suggestions: TipsData[] | null;
}

export interface AdvanceConfigSettingItem {
  code: string;
  name: React.ReactElement | string;
  limitPro: boolean; //是否是高级功能（仅对付费用户开放）
  customTag: boolean;
  default: boolean;
  forbidForPay?: boolean; //该选项是否触发付费，当该选项为高级功能且用户的权益为free时为true
  /** enhance约束选项有二级选项 */
  children?: AdvanceConfigSettingItem[];
}
export interface AdvanceConfigSettings {
  pptAudience?: AdvanceConfigSettingItem[];
  pptModel?: AdvanceConfigSettingItem[];
  pptPage?: AdvanceConfigSettingItem[];
  pptReference?: AdvanceConfigSettingItem[];
  pptTone?: AdvanceConfigSettingItem[];
  pptWordAmount?: AdvanceConfigSettingItem[];
  pptForm?: AdvanceConfigSettingItem[];
  pptImage?: AdvanceConfigSettingItem[];
  /**enhance约束规则 */
  enhanceRule?: AdvanceConfigSettingItem[];
  /** writing */
  writerWordAmount?: AdvanceConfigSettingItem[];
  writerForm?: AdvanceConfigSettingItem[];
}

export interface FakeMessageOption {
  docs?: MediaFile[];
  content?: string;
  regenerate?: boolean;
  isSlidesChat?: boolean;
  imageUrls?: string[];
  promptDeleted?: boolean;
  isAIMessage?: boolean;
  showCursor?: false;
  isUploadToEnhance?: boolean;
  fakeFileContent?: string;
  messageId?: string;
  messageMediaInfo?: MessageMediaInfo;
}

export interface DebugPromptMessage {
  role: string;
  content: string;
}
export interface DebugPrompt {
  messages: DebugPromptMessage[];
  model: string;
  stream: boolean;
  temperature: number;
  max_tokens: number;
}
export interface DebugMessage {
  chatType: string;
  uid: string;
  channelId: string;
  md5: string;
  userMsgId: string;
  assistantMsgId: string;
  user: string;
  prompt: DebugPrompt;
  assistant: string;
  chunk: string;
}

export interface DocResult {
  docId: string;
  name: string;
  content: string;
  ext?: {
    channelId: string;
  };
  createdAt: number;
}

export interface UpsertDocResult {
  docId: string;
  channelId: string;
}

export interface QuestionItem {
  questionType: string;
  questionText: string;
}

export interface ExtractQuestionResponse {
  questions: QuestionItem[];
}

export interface DocListItem {
  docId: string;
  channelId: string;
  name: string;
  createdAt: number;
  updatedAt: number;
}

export interface MetaTag {
  name: string;
  content: string;
}
export interface HelmetConfig {
  title: string;
  meta: MetaTag[];
  alternate?: string;
}
export interface Position {
  top: string | number;
  left: string | number;
}
export type FeatureTipInfo = {
  tag: string;
  defaultValue: 0 | 1;
  title?: string;
  desc?: string;
  icon?: string;
};
export type UserFeatureTips = Pick<FeatureTipInfo, 'tag' | 'defaultValue'>;
export type UserFeatureTipsResult = UserFeatureTips & { value: 0 | 1 };

export type ABResult = {
  [key: string]: 'A' | 'OFF';
};

export type FeatureInfo = { abResList?: ABResult; userTips?: UserFeatureTipsResult[] };

export interface UserPrompt {
  promptId: string;
  content: string;
  name: string;
  saved?: boolean;
  is_new?: boolean;
}

export interface SendParams {
  channelId: string; // 聊天对话框 id
  message: string; // 发送的问题
  model: string; // 使用的模型
  messageIds: string[]; // 重新生成需要删除的 id
  improveId: number | null; // 重新生成 boost 模式的消息（Ask 消息 id）
  richMessageId: string | null; // 重新生成被 rich 的消息（Ask 消息 id）
  isNewChat: boolean; // 是否是新建聊天的 send 请求
  action: string | null; // 发送消息的动作，用于 rich 翻译等特殊场景
  isGeneratePpt: boolean; // 是否是生成 ppt
  isSlidesChat: boolean; // ppt 对话聊天模式 outline | slides
  imageUrls?: string[]; // 输入框上传图片列表
  imageFileNames?: string[]; // 输入框上传图片列表
  roleEnum?: string | null;
  outputLang?: string; //用户设置的输出语言
  advanceConfig?: AdvanceConfig; //doc、pdf转ppt的设置项
  pptCoordinates?: string;
  docPromptTemplateId?: DOC_PROMPT_TEMPLATE_ID | null;
  language?: string | null;
  translateLanguage?: string | null;
  isUploadToEnhance?: boolean;
  fakeFileContent?: string;
  'Cf-Captcha-Token'?: string;
  isOneChatAndDoc?: boolean;
  searchSwitch?: boolean;
  messageId?: string;
  chunkId?: string;
}
