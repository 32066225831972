import { create } from 'zustand';

import type { RcFile } from 'antd/es/upload/interface';

export type UploadResultWithFile = {
  md5: string;
  fileName: string;
  fileContentType: string;
  uploadTime: number;
  languages: string[] | null | undefined;
  uploadedFlag: boolean;
  originFileObj: RcFile;
  mimeType?: string;
};

export interface UploadProgress {
  [key: string]: UploadResultWithFile & { status?: string };
}

export interface RcFileWithPageCount extends RcFile {
  pageCount?: number;
}

interface UploadState {
  /**
   * 是否能上传
   */
  disable: boolean;
  /**
   * 设置是否能上传
   */
  setDisable: (payload: boolean) => void;
  /**
   * 校验状态
   */
  isValidate: boolean;
  /**
   * 设置校验状态
   */
  setIsValidate: (payload: boolean) => void;
  /**
   * 是否是拖动文件
   */
  isDrag: boolean;
  /**
   * 设置是否是拖动文件
   */
  setIsDrag: (payload: boolean) => void;
  /**
   * 是否多文档
   */
  isMultiDoc: boolean;
  /**
   * 设置是否多文档
   */
  setIsMultiDoc: (payload: boolean) => void;
  /**
   * 是否正在上传
   */
  uploading: boolean;
  /**
   * 设置是否正在上传
   */
  setUploading: (payload: boolean) => void;
  /**
   * 上传进度
   */
  uploadProgress: number;
  /**
   * 设置上传进度
   */
  setUploadProgress: (payload: number) => void;
  /**
   * 多文档上传进度
   */
  uploadProgressObj: UploadProgress;
  /**
   * 设置多文档上传进度
   */
  setUploadProgressObj: (payload: UploadProgress) => void;
  allUploadFiles: RcFileWithPageCount[];
  setAllUploadFiles: (payload: RcFileWithPageCount[]) => void;
  currentUploadFiles: RcFile[];
  setCurrentUploadFiles: (payload: RcFile[]) => void;
  /**
   * 上传错误
   */
  uploadError: string;
  /**
   * 设置上传错误
   */
  setUploadError: (payload: string) => void;
  /**
   * 上传错误码
   */
  uploadErrorCode: number;
  /**
   * 设置上传错误
   */
  setUploadErrorCode: (payload: number) => void;
  /**
   * 取消上传
   */
  cancelUpload: () => void;

  /**
   * 上传的文件，用于重新上传
   */
  file: RcFile | null;
  /**
   * 设置上传的文件
   */
  setFile: (payload: RcFile | null) => void;

  /**
   * 上传的 url
   */
  url: string;
  /**
   * 设置上传的 url
   */
  setUrl: (payload: string) => void;

  /**
   * 重置各种状态
   */
  reset: () => void;

  /**
   * 假进度条的定时器
   */
  increaseTimer: number | null;
  /**
   * 通过url上传时，定时增加进度条（等接口响应）
   */
  increaseProgressTo99: () => void;
  /**
   * 新首页是否显示多文档
   */
  showMultiDoc: boolean;
  /**
   * 设置新首页是否显示多文档
   */
  setShowMultiDoc: (payload: boolean) => void;
}

const useUploadStore = create<UploadState>((set) => ({
  disable: false,
  setDisable: (payload: boolean) => set({ disable: payload }),
  isValidate: false,
  setIsValidate: (payload: boolean) => set({ isValidate: payload }),
  isDrag: false,
  setIsDrag: (payload: boolean) => set({ isDrag: payload }),
  isMultiDoc: false,
  setIsMultiDoc: (payload: boolean) => set({ isMultiDoc: payload }),
  uploading: false,
  setUploading: (payload: boolean) => set({ uploading: payload }),
  uploadProgress: 0,
  // 上传文件时，先做个假进度条，真实进度超过假进度时，停止假进度
  setUploadProgress: (payload: number) =>
    set((state) => {
      // pm不要100%
      payload = payload === 100 ? 99 : payload;
      // payload是真进度，state.uploadProgress可能是真或假
      if (payload > state.uploadProgress) {
        // 如果timer存在，说明是假进度，清除timer
        if (state.increaseTimer) {
          clearInterval(state.increaseTimer);
          return { uploadProgress: payload, increaseTimer: null };
        }
        return { uploadProgress: payload };
      }
      return state;
    }),
  uploadProgressObj: {},
  setUploadProgressObj: (payload: UploadProgress) => set({ uploadProgressObj: payload }),
  allUploadFiles: [],
  setAllUploadFiles: (payload: RcFile[]) => set({ allUploadFiles: payload }),
  currentUploadFiles: [],
  setCurrentUploadFiles: (payload: RcFile[]) => set({ currentUploadFiles: payload }),
  uploadError: '',
  setUploadError: (payload: string) =>
    set({ uploadError: payload, uploading: false, uploadProgress: 0 }),
  uploadErrorCode: 0,
  setUploadErrorCode: (payload: number) => set({ uploadErrorCode: payload }),
  cancelUpload: () => set({ uploading: false, uploadProgress: 0, uploadError: '' }),
  file: null,
  setFile: (payload: RcFile | null) => set({ file: payload }),
  url: '',
  setUrl: (payload: string) => set({ url: payload }),
  reset: () =>
    set((state) => {
      // 重置时清除定时器
      if (state.increaseTimer) {
        clearInterval(state.increaseTimer);
      }
      return {
        disable: false,
        isValidate: false,
        isDrag: false,
        uploading: false,
        uploadProgress: 0,
        uploadError: '',
        file: null,
        url: '',
        increaseTimer: null,
        isMultiDoc: false,
        uploadProgressObj: {},
        allUploadFiles: [],
        currentUploadFiles: [],
        uploadErrorCode: 0,
      };
    }),
  increaseTimer: null,
  increaseProgressTo99: () => {
    const timer = window.setInterval(() => {
      set((state) => {
        if (state.uploadProgress < 99) {
          return { uploadProgress: state.uploadProgress + 1 };
        } else {
          clearInterval(timer);
          return { uploadProgress: 99 };
        }
      });
    }, 100);
    set({ increaseTimer: timer });
  },
  showMultiDoc: false,
  setShowMultiDoc: (payload: boolean) => set({ showMultiDoc: payload }),
}));

export default useUploadStore;
