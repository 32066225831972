import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { isFunction } from 'lodash-es';
import { STORE_KEY, defaultUsageInfo } from '@/common/config';
import { basicChatModelName } from '@/common/model';
import type { LanguageModel, Category, Language, ExperimentUser, FeedbackConfig } from '@/type';
import { BillingDetail } from '@/layout/BasicLayout/components/BillingManageModal/interface';

type SiderCollapsedPayload = boolean | ((payload: boolean) => boolean);

export interface LanguageInfoType {
  recommend?: Language[];
  all?: Language[];
}

interface RequirePayInfo {
  requirePay: number;
  errorMsg: string;
  source?: string;
  shortMessage?: string;
}

export interface GptUsageItem {
  used: number;
  total: number;
}
export interface UsageInfo {
  [key: string]: GptUsageItem | string;
}

interface CommonState {
  featureFlags: { [key: string]: string };
  setFeatureFlags: (payload: { [key: string]: string }) => void;
  isRTLDir: boolean;
  setIsRTLDir: (payload: boolean) => void;
  model: string;
  setModel: (payload: string) => void;
  /**
   * 聊天框使用的模型
   */
  chatModel: string;
  /**
   * 设置聊天框使用的模型
   */
  setChatModel: (payload: string) => void;
  modelList: LanguageModel[];
  setModelList: (payload: LanguageModel[]) => void;
  aiCreation: string;
  setAiCreation: (payload: string) => void;
  categories: Category[];
  setCategories: (payload: Category[]) => void;
  languageInfo: LanguageInfoType;
  setLanguageInfo: (payload: LanguageInfoType) => void;
  uploadErrMessage: string;
  setUploadErrMessage: (payload: string) => void;
  usageInfo: UsageInfo;
  setUsageInfo: (payload: UsageInfo) => void;
  billingDetail: BillingDetail | undefined;
  setBillingDetail: (payload: BillingDetail | undefined) => void;
  requirePayInfo: RequirePayInfo;
  setRequirePayInfo: (payload: RequirePayInfo) => void;
  historyOpen: boolean;
  setHistoryOpen: (payload: boolean) => void;
  experiment: ExperimentUser | null;
  setExperiment: (payload: ExperimentUser) => void;
  gpt4v: boolean;
  setGpt4v: (payload: boolean) => void;
  siderCollapsed: boolean;
  setSiderCollapsed: (payload: SiderCollapsedPayload) => void;

  // 用户注册时间+24小时 与 当前时间的时间差(秒)和优惠码，01.31下线
  countDown: number;
  setCountDown: (payload: number) => void;
  countDownCode: string;
  setCountDownCode: (payload: string) => void;
  //界面的语言，用于多语言能力，如阿语和英语的切换
  pageLanguage: string;
  setPageLanguage: (payload: string) => void;
  //模型返回的language
  outputLanguage: Language;
  setOutputLanguage: (payload: Language) => void;
  fillInCookieSettingBanner: boolean;
  setFillInCookieSettingBanner: (payload: boolean) => void;
  feedbackConfig: FeedbackConfig;
  setFeedbackConfig: (payload: FeedbackConfig) => void;
}

const useCommonStore = create<
  CommonState,
  [['zustand/devtools', never], ['zustand/persist', Partial<CommonState>]]
>(
  devtools(
    persist(
      (set) => ({
        featureFlags: {},
        setFeatureFlags: (payload: { [key: string]: string }) =>
          set(() => ({ featureFlags: payload })),
        isRTLDir: false,
        setIsRTLDir: (payload: boolean) => set(() => ({ isRTLDir: payload })),
        model: basicChatModelName,
        setModel: (payload: string) => set(() => ({ model: payload })),
        chatModel: basicChatModelName, //新用户首次登陆默认显示基础模型
        // setChatModel: (payload: string) => set(() => ({ chatModel: payload })),
        setChatModel: (payload: string) => {
          set(() => ({ chatModel: payload }));
        },
        modelList: [],
        setModelList: (payload: LanguageModel[]) => set(() => ({ modelList: payload })),
        aiCreation: '',
        setAiCreation: (payload: string) => set(() => ({ aiCreation: payload })),
        categories: [],
        setCategories: (payload: Category[]) => set(() => ({ categories: payload })),
        languageInfo: {},
        setLanguageInfo: (payload: LanguageInfoType) => set(() => ({ languageInfo: payload })),
        uploadErrMessage: '',
        setUploadErrMessage: (payload: string) => set(() => ({ uploadErrMessage: payload })),
        usageInfo: defaultUsageInfo,
        setUsageInfo: (payload: UsageInfo) =>
          set(({ usageInfo }) => ({ usageInfo: { ...usageInfo, ...payload } })),
        billingDetail: undefined,
        setBillingDetail: (payload: BillingDetail | undefined) =>
          set(() => ({ billingDetail: payload })),
        requirePayInfo: {
          requirePay: 0,
          errorMsg: '',
        },
        setRequirePayInfo: (payload: RequirePayInfo) =>
          set(() => ({ requirePayInfo: { ...payload } })),
        historyOpen: false,
        setHistoryOpen: (payload: boolean) => set(() => ({ historyOpen: payload })),
        experiment: null,
        setExperiment: (payload: ExperimentUser) => set(() => ({ experiment: payload })),
        gpt4v: false,
        setGpt4v: (payload: boolean) => set(() => ({ gpt4v: payload })),
        siderCollapsed: false,
        setSiderCollapsed: (payload: SiderCollapsedPayload) =>
          set(({ siderCollapsed }) => ({
            siderCollapsed: isFunction(payload) ? payload(siderCollapsed) : payload,
          })),

        // 用户注册时间+24小时 与 当前时间的时间差(秒) 和 优惠码
        countDown: 0,
        setCountDown: (payload: number) => set(() => ({ countDown: payload })),
        countDownCode: '',
        setCountDownCode: (payload: string) => set(() => ({ countDownCode: payload })),
        pageLanguage: 'en',
        setPageLanguage: (payload: string) => set(() => ({ pageLanguage: payload })),
        outputLanguage: {},
        setOutputLanguage: (payload: Language) => set(() => ({ outputLanguage: payload })),
        // 是否填写过 cookie-setting-banner
        // true  为填写过 || 手动关闭，false 则没有填写过（展示）
        fillInCookieSettingBanner: false,
        setFillInCookieSettingBanner: (payload: boolean) =>
          set(() => ({ fillInCookieSettingBanner: payload })),
        feedbackConfig: {},
        setFeedbackConfig: (payload: FeedbackConfig) => set(() => ({ feedbackConfig: payload })),
      }),
      {
        name: STORE_KEY.Common,
        storage: createJSONStorage(() => localStorage),
        partialize: ({
          model,
          chatModel,
          usageInfo,
          historyOpen,
          // language,
          pageLanguage,
          outputLanguage,
          fillInCookieSettingBanner,
          featureFlags,
        }: // showPdfImageTip,
        // showPdfSummaryTip,
        CommonState) => ({
          model,
          chatModel,
          usageInfo,
          historyOpen,
          // showPdfImageTip,
          // showPdfSummaryTip,
          // language,
          pageLanguage,
          outputLanguage,
          fillInCookieSettingBanner,
          featureFlags,
        }),
      },
    ),
  ),
);

export default useCommonStore;
