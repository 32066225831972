import cls from 'classnames';
import './index.less';

const NotiDot: React.FC<{
  num: number;
  showMin?: boolean;
  classname?: string;
}> = (props) => {
  const { num, showMin, classname } = props;
  if (!num) {
    return null;
  }
  return <div className={cls('noti-dot', showMin && 'min', classname)}>{showMin ? '' : num}</div>;
};

export default NotiDot;
