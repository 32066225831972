import {
  useEffect,
  useState,
  useRef,
  useMemo,
  lazy,
  Suspense,
  forwardRef,
  useImperativeHandle,
  Ref,
  useCallback,
} from 'react';
import { Layout, Popover, Collapse, App, Divider } from 'antd';
import { Outlet, useOutletContext, useLocation, useNavigate, useParams } from 'react-router-dom';
import qs from 'qs';
import classNames from 'classnames';
import ReactGA from 'react-ga4';
import { isBoolean, takeWhile, takeRightWhile, cloneDeep } from 'lodash-es';
import { useRequest } from 'ahooks';
import { useCommonStore, useUserStore } from '@/store';
import { useFetchUsagesInfo, useGASendEvent, useUsersnap, useDA, useSundry } from '@/hooks';
import {
  getfreeTrialGET,
  getLandingPageInfo,
  getLanguages,
  getConversationList,
  createChannelByShareKey,
  // getPartnerShareLink,
  // getIsPartner,
  signoutGET,
  // userCountDown,
  billingDetailGET,
  memberProvider,
  notificationGET,
} from '@/services';

import {
  STORE_KEY,
  defaultUserInfo,
  defaultUsageInfo,
  defaultUserProperties,
  AB_EXPERIMENTS,
} from '@/common/config';
import { getBaseUrl, isDesktop } from '@/common/utils';
import UserAvatar from '@/components/UserAvatar';
import CustomIcon from '@/components/CustomIcon';
import TKD from '@/components/TDK';
import withRequireAuth from '@/hoc/RequireAuth';
import SideMenu from './components/SideMenu';
import ConfirmModal from './components/ConfirmModal';
import DownPluginModal from './components/DownPluginModal';
import PayModal from './components/PayModal';
import BillingManageModal from './components/BillingManageModal';
import NotLogin from './components/NotLogin';
import Usage from './components/Usage';
import History from './components/History';
import Copyright from './components/Copyright';
import SettingsModal from './components/SettingsModal';
import { useTranslation } from 'react-i18next';
import SettingsMenu from './components/SettingsMenu';
import AutoChangeLanguage from './components/SettingsMenu/autoChangeLanguage';
import i18next from 'i18next';
import { shouldUserUseNewModelName } from '@/common/gray';
import BulletinBanner from './components/BulletinBanner';
import FreeTrialModal from './components/FreeTrialModal';
import CookiesSettingBanner from './components/CookiesSettingBanner';
import QuickEntrance from './components/QuickEntrance';
import UserUsage from './components/UserUsage';
import UserBadge from './components/UserBadge';
import NotiDot from '@/components/NotiDot';
import type { ResponseType, LandingPageInfo, Language, Channel } from '@/type';
import type { BillingDetail } from '@/layout/BasicLayout/components/BillingManageModal/interface';
import useCanonicalURL from './hooks/useCanonicalURL';

import ToastContent from '@/components/ToastContent';
const VoiceFlow = lazy(() => import('./components/VoiceFlow'));

import UpgradeButton from '@/components/UpgradeButton';

import './index.less';
import './index.base.less';
import './index.proAB.less';

// const { VITE_APP_PROMO_URL } = import.meta.env;

interface BasicLayoutProps {
  openLoginModal?: () => void;
  productYearPrice?: string;
  loading?: boolean;
  freeTrial?: number;
}

export interface ChildComponentRef {
  handleOpenBillingManageModalOpen: () => void;
}

interface DropdownItem {
  key: string;
  label: string | React.ReactElement;
  icon?: React.ReactElement;
  onClick?: (event: React.MouseEvent) => void;
}

export type ContextType = {
  checkLoginStatus: (params?: { type?: string; model?: string }) => boolean;
  checkPayStatus: (params?: {
    errorMsg?: string;
    source?: string;
    shortMessage?: string;
    openPriceModal?: boolean;
    skipMobileMemberCheck?: boolean;
  }) => boolean;
  createComplete: (value?: string, search?: boolean) => void;
  handleUpgradeMultiply: (showUpgrade?: boolean) => void;
};

const { Content, Sider } = Layout;

const { VITE_APP_GA_TRACKING_ID, VITE_APP_GA_ADS_ID_OLD, VITE_APP_GA_ADS_ID_NEW } = import.meta.env;

export let landingInfoCache: Partial<LandingPageInfo> = {};

const BasicLayout: React.FC<BasicLayoutProps> = forwardRef((props, ref: Ref<ChildComponentRef>) => {
  const { openLoginModal, productYearPrice, freeTrial: urlFreeTrial } = props;

  const { conversation } = useParams();
  const { sendEvent } = useGASendEvent();
  const { sendDAEvent } = useDA();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // const { fetchUserInfo } = useFetchUserInfo();
  const {
    userInfo,
    shareKey,
    setShareKey,
    setUserInfo,
    setUserProperties,
    setNotiList,
    unreadNotiNumber,
    setUnreadNotiNumber,
  } = useUserStore();
  const {
    model,
    chatModel,
    modelList,
    historyOpen,
    requirePayInfo,
    siderCollapsed,
    // pageLanguage,
    setPageLanguage,
    setModel,
    setChatModel,
    setModelList,
    setAiCreation,
    setCategories,
    setLanguageInfo,
    setHistoryOpen,
    setRequirePayInfo,
    setGpt4v,
    setSiderCollapsed,
    setUsageInfo,
    setFeedbackConfig,
    setBillingDetail,
  } = useCommonStore();
  const { fetchUsagesInfo, updateUsageInfo } = useFetchUsagesInfo();
  const { openFeedback } = useUsersnap();

  useCanonicalURL();

  const { experiment } = useSundry();
  const memberShipFeature = experiment(AB_EXPERIMENTS.MEMBERSHIP);
  const guestFeature = experiment(AB_EXPERIMENTS.GUEST);

  const { message } = App.useApp();
  const [userInfoOpen, setUserInfoOpen] = useState(false);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [payModalOpen, setPayModalOpen] = useState(false);
  const [freeTrial, setFreeTrial] = useState<number>(0); // 是否是free-trial流量，0普通，1free-trial流量
  const [freeTrialOpen, setFreeTrialOpen] = useState(false);
  const [productPrice, setProductPrice] = useState(''); // 产品的价格，
  const [shortMessage, setShortMsg] = useState('');
  const [triggerPaySource, setTriggerPaySource] = useState('');
  const [billingManageModalOpen, setBillingManageModalOpen] = useState(false);
  const [historyPopoverOpen, setHistoryPopoverOpen] = useState(false);
  const [downloadAppOpen, setDownloadAppOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [voiceFlowOpen, setVoiceFlowOpen] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);
  // const [isPartner, setIsPartner] = useState(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false); // 设置语言弹窗
  const isFirstLoadRef = useRef(true);
  const [jumpToMyproject, setJumpToMyproject] = useState<boolean>(false);
  const [autoLanguageModal, setAutoLanguageModal] = useState<boolean>(false);
  const [isWebMember, setWebMember] = useState<boolean>(true);

  // command + k跳转首页
  useEffect(() => {
    if (location.pathname === '/') {
      return;
    }
    if (conversation) {
      return;
    }
    const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
    const cmd = 'metaKey';
    const ctrl = 'ctrlKey';
    const keyboardHandler = (e: KeyboardEvent) => {
      if ((isMac && e.key === 'k' && e[cmd]) || (!isMac && e.key === 'i' && e[ctrl])) {
        e.preventDefault();
        navigate('/');
      }
    };
    window.addEventListener('keydown', keyboardHandler);
    return () => {
      window.removeEventListener('keydown', keyboardHandler);
    };
  }, [location.pathname, conversation]);

  useImperativeHandle(ref, () => ({
    handleOpenBillingManageModalOpen() {
      setBillingManageModalOpen(true);
    },
  }));

  const getBillingDetail = async () => {
    try {
      const resData = await billingDetailGET<ResponseType<BillingDetail>>();
      setBillingDetail(resData.data);
    } catch (e) {
      console.error(e);
    }
  };
  const getMemberProvider = async () => {
    try {
      const res = await memberProvider();
      const webMember = !res.data || ['STRIPE', 'FASTSPRING'].includes(res.data);
      setWebMember(webMember);
    } catch (e) {
      console.error(e);
    }
  };
  const getNotifications = async () => {
    try {
      const res = await notificationGET<
        ResponseType<{
          unReadNumber: number;
          notifications: [];
        }>
      >();
      // TODO: dev mock. replace by res.data
      const { unReadNumber, notifications } = res.data;
      setUnreadNotiNumber(unReadNumber || 0);
      setNotiList(notifications || []);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchIsFreeTrial = async () => {
    try {
      const res = await getfreeTrialGET<ResponseType>();
      setFreeTrial(res.data as unknown as number);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchLandingPageInfo = async () => {
    try {
      const res = await getLandingPageInfo<ResponseType<LandingPageInfo>>();
      const data = res.data;

      landingInfoCache = data;

      setModelList(
        shouldUserUseNewModelName(userInfo)
          ? data?.languageModelList4PCNew
          : data?.languageModelList4PC,
      );

      setCategories(data?.communityList);
      setAiCreation(data?.aiCreationWorkshop);
      setGpt4v(!!data?.gpt4v);
      setFeedbackConfig(data?.feedbackDislikeConfig || {});
    } catch (err) {
      console.log(err);
    }
  };

  const {
    data,
    loading: listLoading,
    run: fetchHistoryList,
  } = useRequest(getConversationList<ResponseType<Channel[]>>, {
    ready: !!userInfo?.isLogin,
    onSuccess: () => {
      if (isFirstLoadRef?.current) {
        isFirstLoadRef.current = false;
      }
    },
  });

  const historyLoading = useMemo(() => {
    return isFirstLoadRef?.current && listLoading;
  }, [listLoading]);

  const fetchLanguages = async () => {
    try {
      const res = await getLanguages<ResponseType<Language[]>>();
      const recommendLanguages = takeWhile(res.data, ({ langcode }) => langcode !== '--');
      const languages = takeRightWhile(res.data, ({ langcode }) => langcode !== '--');

      setLanguageInfo({
        recommend: recommendLanguages,
        all: languages,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const createShareChannel = async (shareKey: string) => {
    setShareKey('');

    try {
      const res = await createChannelByShareKey<ResponseType<Channel>>({
        shareKey,
      });

      if (res.code === 200 && res.data) {
        const data = res.data;

        createComplete(data?.channelId as string, false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const handleIsPartner = async () => {
  //   try {
  //     const res = await getIsPartner<ResponseType>();
  //     if (res.data) {
  //       setIsPartner(true);
  //     } else {
  //       setIsPartner(false);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const handelBrandLogoClick = async () => {
    sendDAEvent('SidebarButtonClick', {
      button_name: 'popai',
      sidebar_status: siderCollapsed ? 'close' : 'open',
    });
    navigate(`/${location?.search}`);
  };

  const openChangeHandler = useCallback(
    (open: boolean) => {
      sendDAEvent('SidebarButtonClick', {
        button_name: 'user',
        sidebar_status: siderCollapsed ? 'close' : 'open',
      });
      setUserInfoOpen(open);
    },
    [sendDAEvent, siderCollapsed],
  );

  useEffect(() => {
    if (requirePayInfo.requirePay > 0) {
      checkPayStatus({
        errorMsg: requirePayInfo.errorMsg,
        source: requirePayInfo?.source,
        shortMessage: requirePayInfo?.shortMessage,
      });
      setRequirePayInfo({
        requirePay: 0,
        errorMsg: '',
        source: '',
        shortMessage: '',
      });
    }
  }, [requirePayInfo]);

  useEffect(() => {
    if (!(isBoolean(userInfo?.isLogin) && userInfo?.isLogin)) return;
    // handleIsPartner();
    fetchIsFreeTrial();
    fetchHistoryList();
  }, [userInfo?.isLogin]);

  useEffect(() => {
    if (shareKey && userInfo?.isLogin) {
      createShareChannel(shareKey);
    }
  }, [userInfo?.isLogin, shareKey]);

  useEffect(() => {
    const queryParams = qs.parse(location?.search?.slice(1));
    const shareKeyValue = queryParams?.shareKey as string;

    if (shareKeyValue) {
      const queryParamsClone = cloneDeep(queryParams);
      delete queryParamsClone?.shareKey;

      setShareKey(queryParams?.shareKey as string);
      navigate(`/?${qs.stringify(queryParamsClone)}`);
    }
  }, []);

  useEffect(() => {
    if (VITE_APP_GA_TRACKING_ID) {
      ReactGA.gtag('consent', 'default', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });
      ReactGA.initialize(VITE_APP_GA_TRACKING_ID, { testMode: import.meta.env.DEV });
      VITE_APP_GA_ADS_ID_OLD && ReactGA.gtag('config', VITE_APP_GA_ADS_ID_OLD);
      VITE_APP_GA_ADS_ID_NEW && ReactGA.gtag('config', VITE_APP_GA_ADS_ID_NEW);
    }
  }, []);

  useEffect(() => {
    fetchLandingPageInfo();
    fetchLanguages();
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    if (!isDesktop()) {
      setVh();
    }
  }, []);

  useEffect(() => {
    if (userInfo.isLogin) {
      fetchLandingPageInfo();
      fetchUsagesInfo();
      getBillingDetail();
      getMemberProvider();
      getNotifications();
    } else {
      setBillingDetail(undefined);
      !guestFeature && updateUsageInfo();
    }
  }, [userInfo.isLogin]);

  useEffect(() => {
    // 如果modelList没有已选的model（3.5改名basic model可能会造成的问题），则默认选中第一个
    const docModels = modelList?.filter((item) => item.role === 'DocChat');
    setModel(
      docModels.length > 0 && !docModels.some((item) => item.name === model)
        ? docModels?.[0]?.name
        : model,
    );
  }, [modelList, model]);

  /**
   * 初始化chatModel
   */
  useEffect(() => {
    const chatModels = modelList?.filter((item) => item.role === 'Chat');
    setChatModel(
      chatModels.length > 0 && !chatModels.some((item) => item.name === chatModel)
        ? chatModels[0].name
        : chatModel,
    );
  }, [chatModel, modelList]);

  // stripe支付 通过成功后的回调URL获取到产品价格
  useEffect(() => {
    if (productYearPrice) {
      setProductPrice(productYearPrice);
      setFreeTrialOpen(true);
    }
  }, [productYearPrice]);

  const labelRender = (item: DropdownItem) => {
    const { key, icon, label, onClick } = item;
    return (
      <div key={key} className="label-container" onClick={onClick}>
        <span className="label-icon">{icon ? icon : null}</span>
        <span className="label-title">{label}</span>
      </div>
    );
  };

  const handleLogout = async () => {
    try {
      await signoutGET<ResponseType>({});
    } finally {
      localStorage.removeItem(STORE_KEY.Authorization);
      setUserInfo(defaultUserInfo);
      setUserProperties(defaultUserProperties);
      setUsageInfo(defaultUsageInfo);
      setLogoutOpen(false);
      const baseUrl = getBaseUrl();
      // logout是后端路由
      window.location.href = `${baseUrl}/logout`;
    }
  };

  const checkLoginStatus = (params: { type?: string; model?: string }) => {
    // const checkModel = params?.model || chatModel;
    // if (guestToken && checkModel === basicChatModelName) {
    //   return true;
    // }

    if (!userInfo.isLogin) {
      openLoginModal?.();
      sendDAEvent('RegistLoginModalExposure');
      sendEvent('Triggerlogin', {
        clickType: params?.type || 'default',
      });
    }

    return !!userInfo.isLogin;
  };

  const handleLogoutCancel = () => {
    setLogoutOpen(false);
  };

  const handleLogoutShow = () => {
    setUserInfoOpen(false);
    setLogoutOpen(true);
  };

  const handleFeedbackShow = () => {
    setUserInfoOpen(false);
    setVoiceFlowOpen(true);
  };

  // const handleReferAndEarn = async () => {
  //   sendEvent('refer_click');
  //   await fetchUserInfo();
  //   const { userInfo } = useUserStore.getState();
  //   if (['ACTIVE', 'PENDING'].includes(userInfo?.partnerStatus as string)) {
  //     try {
  //       const res = await getPartnerShareLink<ResponseType<string>>();
  //       if (res && res.data) {
  //         window.open(res.data, '_blank');
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   } else {
  //     window.open(
  //       `${VITE_APP_PROMO_URL}/affilliateProgram?authorization=${localStorage.getItem(
  //         STORE_KEY.Authorization,
  //       )}`,
  //       '_blank',
  //     );
  //   }
  // };

  const handleDownloadAppShow = (event: React.MouseEvent) => {
    event?.stopPropagation();
    setDownloadAppOpen(true);
  };

  const handleReleaseNotes = (event: React.MouseEvent) => {
    event?.stopPropagation();
    setUserInfoOpen(false);
    sendEvent('AllRelease', {
      releaseSource: 'Set',
    });
    navigate('/releasenote');
  };

  const handleDownloadAppCancel = () => {
    setDownloadAppOpen(false);
  };

  // 获取用户注册时间24小时的时间戳与当前时间戳的差值
  // const fetchUserCountDown = async () => {
  //   try {
  //     const res = await userCountDown<
  //       ResponseType<{
  //         hours?: string;
  //         minutes?: string;
  //         seconds?: string;
  //         code?: string;
  //         over: boolean;
  //       }>
  //     >();
  //     setCountDownOver(!res.data.over);
  //     if (res.data && !res.data.over) {
  //       const seconds =
  //         Number(res.data?.hours || 0) * 3600 +
  //         Number(res.data?.minutes || 0) * 60 +
  //         Number(res.data?.seconds || 0);
  //       setCountDown(seconds);
  //       setCountDownCode(res.data?.code || '');
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const checkPayStatus = async (params?: {
    errorMsg?: string;
    source?: string;
    shortMessage?: string;
    openPriceModal?: boolean;
    skipMobileMemberCheck?: boolean;
  }) => {
    const source = params?.source || requirePayInfo?.source;
    setJumpToMyproject(!!source?.includes('MAXEDITABLEPROJECT'));

    if (!userInfo?.isLogin) {
      openLoginModal?.();
      sendEvent('Triggerlogin', {
        clickType: 'source',
      });
      return false;
    }

    if (userInfo.isPro) {
      if (!userInfo?.parsedRoles?.includes('Trail')) {
        if (!params?.openPriceModal) {
          handleUpgradeMultiply(true);
          return;
        }
      } else {
        message.open({
          content: <ToastContent icon="error" content={t('common.ftNotuUpgrade')} />,
        });
      }
    }
    if (isWebMember || params?.skipMobileMemberCheck) {
      setPayModalOpen(true);
      setShortMsg(params?.shortMessage || '');
      setTriggerPaySource(params?.source || '');
      sendEvent('Triggerpay', {
        source,
      });
      sendDAEvent('ProductPurchaseModalExposure', {
        forward_source: source,
      });
      if (freeTrial && source) {
        sendEvent('Triggerfreetrial', { type: 'Passive' });
      }
    } else {
      message.info(t('common.alreadyMobileMember'));
    }

    return userInfo.isPro;
  };

  const handleUpgradePro = () => {
    checkPayStatus();
    sendEvent('Clickpay');
    if (freeTrial) {
      sendEvent('Triggerfreetrial', { type: 'Click' });
    }
  };

  const handlePayClose = () => {
    setPayModalOpen(false);
    setShortMsg('');
    if (jumpToMyproject) {
      setJumpToMyproject(false);
      const path = `/ai-presentation`;
      navigate(path);
    }
  };

  // fs成功后的callback
  const handlePaySuccess = (productYearPrice: string) => {
    setProductPrice(productYearPrice);
    setFreeTrialOpen(true);
  };

  const handleBillingManageClose = () => {
    setBillingManageModalOpen(false);
    if (jumpToMyproject) {
      setJumpToMyproject(false);
      const path = `/ai-presentation`;
      navigate(path);
    }
  };
  /*
   * showUpgrade:打开后是否直接显示升级页面（free, pro, unlimited三栏）
   */
  const handleSubscription = () => {
    sendEvent('Managerbilling');
    if (freeTrial) {
      sendEvent('Freetrialmanagerbilling');
    }
    handleUpgradeMultiply(false);
  };

  const handleUpgradeMultiply = (showUpgrade?: boolean) => {
    if (showUpgrade && !isWebMember) {
      message.info(t('common.alreadyMobileMember'));
      return;
    }
    setShowUpgrade(!!showUpgrade);
    setBillingManageModalOpen(true);
  };

  const onUpgrade = () => {
    setBillingManageModalOpen(false);
    handleUpgradePro();
  };

  const handleFreeTrialClose = () => {
    setFreeTrialOpen(false);
  };

  const handleUserInfoOpen = (open: boolean) => {
    sendDAEvent('SidebarButtonClick', {
      button_name: 'user',
      sidebar_status: siderCollapsed ? 'close' : 'open',
    });
    if (open && !memberShipFeature) {
      fetchUsagesInfo();
    }

    setUserInfoOpen(open);
  };

  const handleHistoryOpenChange = useCallback(
    (keys: string | string[]) => {
      const open = keys?.length > 0;
      setHistoryOpen(open);
      sendEvent(open ? 'Openhistory' : 'Closehistory');
      sendDAEvent('SidebarButtonClick', {
        button_name: open ? 'chat_history_open' : 'chat_history_close',
        sidebar_status: siderCollapsed ? 'close' : 'open',
      });
    },
    [sendDAEvent, sendEvent, setHistoryOpen, siderCollapsed],
  );

  const handleSiderCollapsedChange = () => {
    setSiderCollapsed((collapsed) => !collapsed);
    sendEvent(siderCollapsed ? 'Showsidebar' : 'Hidesidebar');
  };

  const createComplete = (value?: string, search = true) => {
    fetchHistoryList();

    if (value) {
      navigate(`/chat/${value}${search ? location?.search : ''}`);
    }
  };

  const handleSettingsOpen = () => {
    setSettingsOpen(true);
  };

  const handleSettingsCancel = () => {
    setSettingsOpen(false);
  };

  const handleOpenFeedback = () => {
    setVoiceFlowOpen(false);
    openFeedback();
  };

  const handleHistoryPopoverOpen = useCallback(
    (open: boolean) => {
      setHistoryPopoverOpen(open);
      sendDAEvent('SidebarButtonClick', {
        button_name: open ? 'chat_history_open' : 'chat_history_close',
        sidebar_status: siderCollapsed ? 'close' : 'open',
      });
    },
    [sendDAEvent, siderCollapsed],
  );

  const items: (DropdownItem | boolean)[] = [
    !!memberShipFeature && {
      key: 'pricing',
      icon: <CustomIcon type="rocket" />,
      label: t('layout.usersnap.pricing'),
      onClick: () => {
        checkPayStatus?.({
          openPriceModal: true,
        });
      },
    },
    {
      key: 'notify',
      icon: <CustomIcon type="rocket" />,
      label: (
        <>
          {t('layout.usersnap.notify')}
          <NotiDot num={unreadNotiNumber} />
        </>
      ),
      onClick: () => {
        setUserInfoOpen(false);
        navigate('/notify');
      },
    },
    {
      key: 'feedback',
      icon: <CustomIcon type="feedback" />,
      label: t('layout.usersnap.feedback'),
      onClick: handleFeedbackShow,
    },
    // userInfo.partnerStatus !== 'BLOCKED' &&
    //   pageLanguage !== 'ar' &&
    //   isPartner && {
    //     key: 'Refer & Earn',
    //     icon: <CustomIcon type="referAndEarn" />,
    //     label: t('layout.usersnap.refer'),
    //     onClick: handleReferAndEarn,
    //   },
    {
      key: 'Download iOS App/extension',
      icon: <CustomIcon type="mobile" />,
      label: t('layout.usersnap.donwloadApp'),
      onClick: handleDownloadAppShow,
    },
    {
      key: 'Release Notes',
      icon: <CustomIcon className="release-notes" type="notes" />,
      label: t('layout.usersnap.ReleaseNotes'),
      onClick: handleReleaseNotes,
    },
    {
      key: 'Setting',
      icon: <CustomIcon type="setting" />,
      label: t('layout.usersnap.setting'),
      onClick: () => setSettingsMenuOpen(true),
    },
    import.meta.env?.VITE_APP_NAME !== 'production' && {
      key: 'Debug',
      icon: <CustomIcon type="setting" />,
      label: 'Debug',
      onClick: handleSettingsOpen,
    },
    !!userInfo.isLogin && {
      key: 'Log out',
      icon: <CustomIcon type="logout" />,
      label: t('common.logOut'),
      onClick: handleLogoutShow,
    },
  ].filter(Boolean);

  const userInfoPopoverContentRender = () =>
    memberShipFeature ? (
      <div className="user-menu-list upgrade">
        {items.map((item) => {
          const { key, icon, label, onClick } = item as DropdownItem;
          return (
            <div key={key} className="menu-item-container" onClick={onClick}>
              <span className="label-icon">{icon ? icon : null}</span>
              <span className="label-title">{label}</span>
            </div>
          );
        })}
      </div>
    ) : (
      <>
        {!!userInfo.isLogin && <Usage onSubscription={handleSubscription}>{userBadge()}</Usage>}
        <div className="user-menu-list">
          {items.map((item) => labelRender(item as DropdownItem))}
        </div>
      </>
    );

  const expandedHistory = useMemo(() => {
    return (
      <div className="history-container-expanded">
        <Collapse
          rootClassName="history-collapse-container"
          activeKey={historyOpen ? 'history' : ''}
          ghost
          bordered={false}
          expandIcon={() =>
            historyOpen ? (
              <CustomIcon className="arrow-icon-active" type="arrowBottomSquare" />
            ) : (
              <div className="isRtlIcon_language_ar">
                <CustomIcon className="arrow-icon" type="arrowRightSquare" />
              </div>
            )
          }
          expandIconPosition="end"
          items={[
            {
              key: 'history',
              label: (
                <div
                  className={classNames({
                    'collapse-title': true,
                    'collapse-title-active': historyOpen,
                  })}
                >
                  <CustomIcon className="time-icon" type={'popai-history'} />
                  {t('layout.chatHistoryLabel')}
                </div>
              ),
              children: (
                <History
                  dataSource={data?.data || []}
                  loading={historyLoading}
                  onOperationComplete={fetchHistoryList}
                />
              ),
            },
          ]}
          onChange={handleHistoryOpenChange}
        />
      </div>
    );
  }, [historyOpen, t, data?.data, historyLoading, fetchHistoryList, handleHistoryOpenChange]);

  const collapsedHistoryRender = () => (
    <Popover
      overlayClassName="history-dropdown"
      placement="right"
      open={historyPopoverOpen}
      destroyTooltipOnHide
      arrow={false}
      content={
        <div className="collapsed-history-wrapper">
          <div className="history-title">{t('layout.chatHistoryLabel')}</div>
          <div className="history-wrapper">
            <History
              collapsed
              dataSource={data?.data || []}
              onSelected={() => handleHistoryPopoverOpen(false)}
              onOperationComplete={fetchHistoryList}
            />
          </div>
        </div>
      }
      onOpenChange={handleHistoryPopoverOpen}
    >
      <div className="history-container-collapsed">
        <CustomIcon className="time-icon" type={'popai-history'} />
      </div>
    </Popover>
  );

  const userBadge = () => {
    const isFreeTrialUser = userInfo?.parsedRoles?.includes('Trail');
    const showFreeTraiIcon = isFreeTrialUser && !siderCollapsed;
    return userInfo?.isUnlimited ? (
      <div className="user-badge">
        {showFreeTraiIcon ? <CustomIcon type="unlimitFt" /> : <CustomIcon type="unlimited" />}
      </div>
    ) : userInfo?.isProPlus ? (
      <div className="user-badge">
        {showFreeTraiIcon ? <CustomIcon type="proplusFt" /> : <CustomIcon type="proPlus" />}
      </div>
    ) : (
      userInfo?.isPro && (
        <div className="user-badge">
          {showFreeTraiIcon ? <CustomIcon type="proFt" /> : <CustomIcon type="proBadge" />}
        </div>
      )
    );
  };

  const expandedUserInfoRender = () => (
    <div className={classNames('expanded-wrapper', memberShipFeature && 'upgrade')}>
      <Popover
        overlayClassName={classNames(
          'setting-dropdown',
          'upgrade-setting-dropdown',
          memberShipFeature && 'membership',
        )}
        placement="top"
        trigger="click"
        arrow={false}
        open={userInfoOpen}
        zIndex={1000}
        onOpenChange={handleUserInfoOpen}
        content={userInfoPopoverContentRender()}
      >
        {memberShipFeature ? (
          <>
            <UserUsage
              freeTrial={freeTrial}
              isWebMember={isWebMember}
              onLogin={openLoginModal}
              onUpgrade={() => {
                checkPayStatus?.({
                  openPriceModal: true,
                  skipMobileMemberCheck: true,
                });
              }}
            />
            <Divider style={{ padding: 0, margin: '8px 0 12px' }} />
          </>
        ) : (
          <div className="user-info-content">
            <div className="user-info">
              <UserAvatar
                className={classNames({
                  'user-avatar-login': userInfo.isLogin,
                })}
                size={32}
                bordered={!userInfo?.picture}
                src={userInfo?.picture}
              />
              {userBadge()}
            </div>
          </div>
        )}
      </Popover>
      <QuickEntrance handleDownloadAppShow={handleDownloadAppShow} />
    </div>
  );

  const collapsedUserInfoRender = () => (
    <Popover
      overlayClassName={classNames(
        'setting-dropdown setting-dropdown-collapsed',
        'upgrade-setting-dropdown',
      )}
      placement="topLeft"
      trigger="click"
      arrow={false}
      autoAdjustOverflow={false}
      open={userInfoOpen}
      zIndex={1000}
      onOpenChange={openChangeHandler}
      content={userInfoPopoverContentRender()}
    >
      <div className={classNames('collapsed-avatar-wrapper', memberShipFeature && 'upgrade')}>
        <UserAvatar
          className={classNames({
            'user-avatar-login': userInfo.isLogin,
          })}
          size={32}
          bordered={!userInfo?.picture}
          src={userInfo?.picture}
        />
        {memberShipFeature ? (
          <div className="user-badge-container">
            <UserBadge
              renderType="fixedIcon"
              siderCollapsed={siderCollapsed}
              isShort={siderCollapsed}
            />
          </div>
        ) : (
          userBadge()
        )}
      </div>
    </Popover>
  );

  const expandedSiderRender = () => (
    <>
      <div className="brand-logo-container-expanded" onClick={handelBrandLogoClick}>
        <CustomIcon className="logo" type="logoUpgrade" />
        {t('layout.brandName')}
      </div>
      <div className="sider-divider-expanded" />
      <SideMenu className="feature-menu" userInfo={userInfo} checkLoginStatus={checkLoginStatus} />
      <div className="sider-divider-expanded" />
      <SideMenu
        isFooter={true}
        userInfo={userInfo}
        checkLoginStatus={checkLoginStatus}
        style={{ marginBottom: '4px' }}
      />
      {expandedHistory}
      <div className="sider-footer-expanded">
        {memberShipFeature ? (
          <div className="user-info-container">
            <div className="sider-divider-expanded-transparency"></div>
            <div className="sider-divider-expanded sider-divider-expanded-short" />
            {expandedUserInfoRender()}
          </div>
        ) : userInfo.isLogin ? (
          <>
            {/* <div className="questionnaire" onClick={handleJumpQuestionnaire}></div> */}
            <div className="user-info-container">
              <div className="sider-divider-expanded-transparency"></div>
              <div className="sider-divider-expanded sider-divider-expanded-short" />
              {expandedUserInfoRender()}
            </div>
            <UpgradeButton freeTrial={freeTrial} onUpgrade={handleUpgradePro} />
          </>
        ) : (
          <>
            <NotLogin openLoginModal={openLoginModal} />
            <QuickEntrance handleDownloadAppShow={handleDownloadAppShow} />
          </>
        )}
        {import.meta.env.VITE_APP_NAME === 'approve' && <Copyright />}
      </div>
    </>
  );

  const collapsedSiderRender = () => (
    <>
      <div className="brand-logo-container-collapsed" onClick={handelBrandLogoClick}>
        <CustomIcon className="logo" type="logoUpgrade" />
      </div>
      <div className="sider-divider-collapsed" />
      <div className="menu-group">
        <SideMenu
          className="feature-menu"
          collapsed
          userInfo={userInfo}
          checkLoginStatus={checkLoginStatus}
        />
        <SideMenu
          collapsed
          isFooter={true}
          userInfo={userInfo}
          checkLoginStatus={checkLoginStatus}
        />
        {collapsedHistoryRender()}
      </div>
      <div className="sider-footer-collapsed">
        {/* {userInfo?.isLogin && (
          <div className="collapse-questionnaire" onClick={handleJumpQuestionnaire}></div>
        )} */}
        {collapsedUserInfoRender()}
        {!memberShipFeature && userInfo?.isLogin && !userInfo?.isPro && (
          <CustomIcon className="membership-icon" type="membership" onClick={handleUpgradePro} />
        )}
      </div>
    </>
  );

  // 语言自动切换弹窗
  const autoModalIsShow = () => {
    const isAutoChangeLanguage = localStorage.getItem('isAutoChangeLanguage');
    const language = (navigator?.language || navigator?.languages[0]) ?? 'en';
    // userInfo.isNew == 1 为新用户 == 0 为老用户
    // 如果是老用户且主语言是阿拉伯就弹出是否要切换语言弹窗
    const condition =
      isAutoChangeLanguage !== 'true' && language === 'ar' && i18next.language !== 'ar';
    if (condition && userInfo.isNew === 0) {
      setAutoLanguageModal(true);
    } else if (condition && userInfo.isNew === 1) {
      // 如果是新用户且主语言是阿拉伯就直接切换语言
      i18n.changeLanguage('ar');
      setPageLanguage('ar');
      localStorage.setItem('isAutoChangeLanguage', 'true');
    }
  };

  // 未登录状态切换语言逻辑判断

  const unLoginAutoChangeLanguage = () => {
    const isAutoChangeLanguage = localStorage.getItem('isAutoChangeLanguage');
    // 如果手动设置过切换语言未登录状态下就不自动跟随web浏览器语言
    if (isAutoChangeLanguage === 'true') {
      return;
    } else {
      // 获取浏览器主语言，如果浏览器主语言是阿拉伯或英语就切换对应语言
      const language = navigator?.language || navigator?.languages[0];
      if (language === 'ar') {
        i18n.changeLanguage(language);
        setPageLanguage(language);
      }
    }
  };

  useEffect(() => {
    if (userInfo?.isLogin) {
      // 已登录状态判断是否是新老用户来设置切换语言弹窗
      autoModalIsShow();
    } else {
      // 未登录状态判断是否是新老用户来设置切换语言弹窗
      unLoginAutoChangeLanguage();
    }
  }, [userInfo]);

  return (
    <>
      <TKD />
      <BulletinBanner />
      <CookiesSettingBanner />
      <Layout className="layout-container upgrade">
        <Sider className="layout-sider-wrapper" width={siderCollapsed ? 56 : 256}>
          <div
            className={classNames({
              'layout-sider': true,
              'layout-sider-expanded': !siderCollapsed,
              'layout-sider-collapsed': siderCollapsed,
            })}
          >
            {siderCollapsed ? collapsedSiderRender() : expandedSiderRender()}
          </div>
          <button className="layout-sider-toggle-btn" onClick={handleSiderCollapsedChange}>
            {siderCollapsed ? (
              <CustomIcon className="toggle-btn-icon" type="rightArrow" />
            ) : (
              <CustomIcon className="toggle-btn-icon" type="leftArrow" />
            )}
          </button>
        </Sider>
        <Content className="layout-content-wrapper">
          <div className="layout-content">
            <Outlet
              context={{
                checkLoginStatus,
                checkPayStatus,
                createComplete,
                handleUpgradeMultiply,
              }}
            />
          </div>
        </Content>
        <ConfirmModal
          open={logoutOpen}
          onCancel={handleLogoutCancel}
          onConfirm={handleLogout}
          title={t('layout.confirmLogout')}
          desc={t('layout.LogoutDescription')}
          okText={t('common.logOut')}
          cancelText={t('common.cancel')}
        />
        <DownPluginModal open={downloadAppOpen} onCancel={handleDownloadAppCancel} />
        <PayModal
          open={payModalOpen}
          freeTrial={freeTrial}
          shortMessage={shortMessage}
          triggerPaySource={triggerPaySource}
          onClose={handlePayClose}
          paySuccess={handlePaySuccess}
          handleUpgradeMultiply={handleUpgradeMultiply}
        />
        <BillingManageModal
          showUpgrade={showUpgrade}
          open={billingManageModalOpen}
          onClose={handleBillingManageClose}
          onUpgrade={onUpgrade}
        />
        {/* free-trial支付成功弹窗 */}
        <FreeTrialModal
          freeTrial={urlFreeTrial}
          open={freeTrialOpen}
          productPrice={productPrice}
          onClose={handleFreeTrialClose}
        />
        {import.meta.env?.VITE_APP_NAME !== 'production' && (
          <SettingsModal open={settingsOpen} onCancel={handleSettingsCancel} />
        )}
        {voiceFlowOpen && (
          <Suspense>
            <VoiceFlow
              open={voiceFlowOpen}
              setOpen={setVoiceFlowOpen}
              openFeedback={handleOpenFeedback}
            />
          </Suspense>
        )}
        <SettingsMenu open={settingsMenuOpen} onClose={setSettingsMenuOpen} />
        <AutoChangeLanguage isShow={autoLanguageModal} setIsShow={setAutoLanguageModal} />
      </Layout>
    </>
  );
});

export default withRequireAuth(BasicLayout);

export function useGlobalModal() {
  return useOutletContext<ContextType>();
}
