import { AiModelMap } from '@/type';
import {
  basicChatModelNameOld,
  basicChatModelName,
  proChatModelName,
  basicDocModelNameOld,
  basicDocModelName,
  proDocModelName,
  dallModelName,
  WebBrowsingName,
  gpt4oName,
  ImageGenerationName,
  gpto1MiniName,
} from './model';
/**
 * 由于`utils.ts`文件太长，这里新建了一个文件，避免文件越来越长
 */

/**
 * 生成模型icon的配置
 */
function getModelConfig({ model, style }: { model: string; style: React.CSSProperties }) {
  return {
    name: model,
    style: style,
  };
}

/**
 * 获取基础模型配置
 */
export function getBasicModelConfig({ fontSize = '40px' }) {
  return getModelConfig({
    model: 'modelBasic',
    style: { fontSize, background: 'linear-gradient(199deg, #ff70c9, #cb6ded, #8483ff)' },
  });
}

/**
 * 获取Web Search 模型配置
 */
export function getWebBrowsingConfig({ fontSize = '40px' }) {
  return getModelConfig({
    model: 'webSearchIcon',
    style: { fontSize, background: '#3C38FF' },
  });
}

/**
 * 获取专业模型配置
 */
export function getProModelConfig({ background = '#AB68FF', fontSize = '26px' }) {
  return getModelConfig({ model: 'gpt', style: { background, fontSize } });
}

/**
 * 获取web search 模型配置
 */
export function getWebSearchModelConfig({ background = '#000', fontSize = '26px' }) {
  return getModelConfig({ model: 'gpt', style: { background, fontSize } });
}

/**
 * 获取生图模型配置
 */
export function getImageGenerationModelConfig({ background = '#000', fontSize = '26px' }) {
  return getModelConfig({ model: 'gpt', style: { background, fontSize } });
}

// 对话头像
export const aiModelAvatar: AiModelMap = {
  [basicChatModelNameOld]: getBasicModelConfig({ fontSize: '32px' }),
  [basicDocModelNameOld]: getBasicModelConfig({ fontSize: '32px' }),
  [basicChatModelName]: getBasicModelConfig({ fontSize: '32px' }),
  [basicDocModelName]: getBasicModelConfig({ fontSize: '32px' }),
  [proChatModelName]: getProModelConfig({ fontSize: '20px' }),
  [proDocModelName]: getProModelConfig({ fontSize: '20px' }),
  [gpt4oName]: getProModelConfig({ fontSize: '20px' }),
  [gpto1MiniName]: getProModelConfig({ fontSize: '20px' }),
  [dallModelName]: getProModelConfig({
    background: '#2e2e2e',
    fontSize: '20px',
  }),
  [WebBrowsingName]: getBasicModelConfig({ fontSize: '32px' }),
  [ImageGenerationName]: getProModelConfig({ background: '#000', fontSize: '20px' }),
};

// 对话列表中的 AI 模型图标
export const messageAiModelMap: AiModelMap = {
  [basicChatModelNameOld]: getProModelConfig({ fontSize: '26px', background: '#19C37D' }),
  [basicDocModelNameOld]: getProModelConfig({ fontSize: '26px', background: '#19C37D' }),
  [basicChatModelName]: getBasicModelConfig({ fontSize: '32px' }),
  [basicDocModelName]: getBasicModelConfig({ fontSize: '32px' }),
  [WebBrowsingName]: getBasicModelConfig({ fontSize: '32px' }),
  [proChatModelName]: getProModelConfig({ fontSize: '26px' }),
  [proDocModelName]: getProModelConfig({ fontSize: '26px' }),
  [gpt4oName]: getProModelConfig({ fontSize: '26px' }),
  [gpto1MiniName]: getProModelConfig({ fontSize: '26px' }),
  [dallModelName]: getProModelConfig({ background: '#2e2e2e', fontSize: '26px' }),
  [ImageGenerationName]: getProModelConfig({ background: '#000', fontSize: '26px' }),
};

// 右上角 AI 模型图标
export const iconAiModelMap: AiModelMap = {
  [basicChatModelNameOld]: getProModelConfig({ fontSize: '12px', background: '#19C37D' }),
  [basicDocModelNameOld]: getProModelConfig({ fontSize: '12px', background: '#19C37D' }),
  [basicChatModelName]: getBasicModelConfig({ fontSize: '16px' }),
  [basicDocModelName]: getBasicModelConfig({ fontSize: '16px' }),
  [WebBrowsingName]: getWebBrowsingConfig({ fontSize: '16px' }),
  [proChatModelName]: getProModelConfig({ fontSize: '12px' }),
  [proDocModelName]: getProModelConfig({ fontSize: '12px' }),
  [gpt4oName]: getProModelConfig({ fontSize: '12px' }),
  [gpto1MiniName]: getProModelConfig({ fontSize: '12px' }),

  [dallModelName]: getProModelConfig({
    background: '#2e2e2e',
    fontSize: '12px',
  }),
  [ImageGenerationName]: getProModelConfig({ background: '#000', fontSize: '12px' }),
};
