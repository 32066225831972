import { Presentation_Themes } from '../PresentationView';
import { basicChatModelName, gpt4oName, proChatModelName, gpto1MiniName } from '@/common/model';

// 输入框最大允许输入的行数
export const INPUT_MAX_ROWS = 7;
// 命中灰度时， 输入框最大允许输入的行数
export const UPDRGE_INPUT_MAX_ROWS = 2;
// 模型选择框选中样式
export const MODEL_SELECTED_STYLE = {
  minWidth: '92px',
};
// 输入框默认placeholder
export const MAX_COUNT = 16000;
export const SEARCH_DEBOUNCE_TIME = 500;
export const SEARCH_TEMPLATE_KEYWORDS_AT = '@';
export const SEARCH_TEMPLATE_KEYWORDS = '/';
export const DEFAULT_IMAGE_MESSAGE = 'Imagechat';
export const DEFAULT_PPT_TPL = {
  mediaType: 'ppt',
  name: 'Create new presentation',
  teaser: 'Generate PPT/slides for presentation and speech',
  thumbnail:
    'https://popaife.s3.ap-southeast-1.amazonaws.com/template/template-new-presentation.png',
};

// 默认的PPT模版
export const DEFAULT_RECENT_TPL = [
  {
    id: 20000,
    name: 'Create presentation',
    nameKey: 'components.send.createNewPPT',
    nameI18n: '{"en":"Create new presentation","ar":"إنشاء عرض تقديمي جديد"}',
    connectionName: 'PPTOUTLINESEARCH',
    source: 'OPENAI',
    promptTemplateId: '20000',
    community: 'Presentation',
    prompt: '[PROMPT]',
    promptHint: 'Enter your presentation topic',
    promptHintKey: 'components.send.promptHint',
    promptHintI18n:
      '{"en": "Enter your presentation topic", "ar": "أدخل موضوع العرض التقديمي الخاص بك"}',
    nextStep: false,
    enrich: false,
    teaser: 'Generate PPT/slides for presentation and speech',
    teaserKey: 'components.send.teaser',
    teaserI18n:
      '{"en":"Generate PPT/slides for presentation and speech","ar":"إنشاء PPT/شرائح للعرض التقديمي والخطبة"}',
    usages: 1001000,
    order: 2000000,
    views: 0,
    model: 'GPT-4',
    realUseModel: 'GPT-3.5-1106',
    temperature: 0.0,
    maxTokens: 2000,
    functionCall:
      '[\n    {\n        "name": "Search",\n        "description": "An assistant tasked with collect the realtime sources from Google and improving Google search results.",\n        "parameters": {\n            "type": "object",\n            "properties": {\n                "query": {\n                    "type": "string",\n                    "description": "The keywords you want search in Google, e.g. Llama2 vs ChatGPT"\n                },\n                "num": {\n                    "type": "string",\n                    "description": "the number of search result wanted, e.g. 12"\n                }\n            },\n            "required": [\n                "query"\n            ]\n        }\n    }\n]',
    gpt3Usage: 1,
    gpt4Usage: 0,
    thumbnail:
      'https://popaife.s3.ap-southeast-1.amazonaws.com/template/template-new-presentation.png',
    newThumbnail:
      'https://popaife.s3.ap-southeast-1.amazonaws.com/template/Presentation/Create+new+presentation.en.png',
    newThumbnailI18n:
      '{"en":"https://popaife.s3.ap-southeast-1.amazonaws.com/template/Presentation/Create+new+presentation2.en.png","ar":"https://popaife.s3.ap-southeast-1.amazonaws.com/template/Presentation/Create+new+presentation2.ar.png"}',
    shareKey: '399013c69a6428a1eb30bd003e039fd1482336f4acb46507dba5d3b80b1efc0e',
    shareKeyI18n:
      '{"en":"399013c69a6428a1eb30bd003e039fd1482336f4acb46507dba5d3b80b1efc0e","ar":"77d60bb9100b733db6204c23455db743c3e4e84ad715825c2b20ff3e1b842cef"}',
    newTip: false,
    infoCollect: true,
    mediaType: 'ppt',
    themeName: 'blue' as Presentation_Themes,
    deleted: false,
    hasTool: false,
    createdAt: 1696936907000,
    updatedAt: 1698423462000,
    collected: false,
  },
];

export const DEFAULT_WRITING_TPL = {
  id: 120013,
  name: 'Writing',
};

export const DEFAULT_MORE_TPL = {
  name: 'More',
};

export const OUTPUT_MODEL = [
  {
    name: basicChatModelName,
    value: 'Standard',
    isShow: true,
  },
  {
    name: proChatModelName,
    value: 'GPT-4',
    isShow: true,
  },
];

export const UPDATE_OUTPUT_MODEL = [
  {
    name: proChatModelName,
    value: 'GPT-4',
    isShow: true,
    desc: 'Classic model',
  },
  {
    name: gpt4oName,
    value: 'GPT-4o',
    isShow: true,
    desc: ' Advanced model with all tools (incl. image generation)',
  },
  {
    name: basicChatModelName,
    value: 'Standard',
    isShow: true,
    desc: 'Fastest model with web search',
  },
  {
    name: gpto1MiniName,
    value: 'o1-mini',
    isShow: true,
  },
];

// 灰度下的默认语言
export const UPGRADE_DEFAULT_LANG = 'Auto';

// enhance ppt允许文档的最大页数
export const ENHANCE_FILE_MAX_PAGES = 100;

// 模版默认缩略图
export const DEFAULT_THUMBNAIL =
  'https://popaife.s3-accelerate.amazonaws.com/home-quick-access/template-thumbnail-default.svg';

// 模版默认sharekey
export const DEFAULT_TPL_SHARE_KEY = 'defaultShareKey';
