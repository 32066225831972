/*
 * @Author: wubo
 * @Date: 2023-09-19 16:18:48
 * @Description:
 * @LastEditTime: 2023-10-07 18:10:33
 * @LastEditors: wubo
 */
import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { isFunction } from 'lodash-es';
import { DEFAULT_OCR_LANGUAGE, STORE_KEY } from '@/common/config';

import { PPT_TYPE } from '@/common/config';
import type {
  Channel,
  SendOptions,
  MessageItem,
  Language,
  HiresImage,
  ImageChatWithType,
  AdvanceConfigSettings,
  AdvanceConfigSettingItem,
  EMediaType,
} from '@/type';

interface TimeMap {
  [key: string]: number;
}

type MessagesPayload = MessageItem[] | ((payload: MessageItem[]) => MessageItem[]);
export type MessagePayload = MessageItem | ((payload: MessageItem) => MessageItem);
type ChannelPayload = Channel | ((payload: Channel) => Channel);
type PPTStep = 0 | 1 | 2 | 3;

export interface FileAnchor {
  anchorId?: string;
  pageNumber?: number;
  source?: string;
}

export interface PPTEnrichInfo {
  text: string;
  action?: string;
  markId: string;
}

interface ChatState {
  channel: Channel;
  setChannel: (payload: ChannelPayload) => void;
  isOneChatChannel?: boolean;
  setIsOneChatChannel: (payload?: boolean) => void;
  showOneChatGUI: boolean;
  setShowOneChatGUI: (payload: boolean) => void;
  sendOptions: SendOptions;
  setSendOptions: (payload: SendOptions) => void;
  timeMap: TimeMap;
  setTimeMap: (payload: TimeMap | string) => void;
  messages: MessageItem[];
  setMessages: (payload: MessagesPayload) => void;
  /**
   * @description: 消息是否已经加载完毕，用来判断messages是空数组的时候，是真的没消息，还是消息还没加载完
   * 其实应该把messages改成 MessageItem[] | undefined
   * undefined表示还没加载完，空数组表示加载完了，但是这样改，会导致很多地方都要改，所以就先这样吧
   */
  messagesReady: boolean;
  setMessagesReady: (payload: boolean) => void;
  fileAnchor: FileAnchor;
  setFileAnchor: (payload: FileAnchor) => void;
  localFileUpload: boolean;
  setLocalFileUpload: (payload: boolean) => void;
  selectionLanguage: Language;
  setSelectionLanguage: (payload: Language) => void;
  pptSendType: PPT_TYPE | null;
  setPptSendType: (payload: PPT_TYPE | null) => void;
  pptActiveCardId: string;
  setPptActiveCardId: (payload: string) => void;
  jumpSlides: boolean;
  setJumpSlides: (payload: boolean) => void;
  showStepBar: boolean;
  setShowStepBar: (payload: boolean) => void;
  pptStep: PPTStep;
  setPptStep: (payload: PPTStep) => void;
  pptEditedTime: number; // 当前ppt编辑时间
  setPptEditedTime: (payload: number) => void;
  description?: string;
  setDescription: (payload: string) => void;
  pptEnrichInfo?: PPTEnrichInfo;
  setPPTEnrichInfo: (payload: PPTEnrichInfo) => void;
  editAction?: string; //编辑类型：edit,shortEdit, longEdit, continueEdit
  setEditAction: (payload: string) => void;
  lastPPTTemplateId?: number; // 用于记录上一次被点击的PPT模板
  setLastPPTTemplateId: (payload: number) => void;
  pptSettings?: AdvanceConfigSettings;
  setPptSettings: (payload: AdvanceConfigSettings) => void;
  pptAudienceCustomize?: AdvanceConfigSettingItem[];
  setPptAudienceCustomize: (payload: AdvanceConfigSettingItem[]) => void;
  pptSlidesCustomize?: AdvanceConfigSettingItem[];
  setPptSlidesCustomize: (payload: AdvanceConfigSettingItem[]) => void;
  outputModel?: string; //输入框中的
  setOutputModel: (payload: string) => void;
  // #region PDF提取图片
  activeImageId?: number;
  setActiveImageId: (payload?: number) => void;
  imageChatWith?: ImageChatWithType;
  setImageChatWith: (payload?: ImageChatWithType) => void;
  hiresImages?: HiresImage[];
  setHiresImages: (payload?: HiresImage[]) => void;
  // #endregion PDF提取图片
  ocrLanguage: Language;
  setOcrLanguage: (payload: Language) => void;

  // 输入框上传PDF文档时的OCR语言
  sendOcrLanguage: Language;
  setSendOcrLanguage: (payload: Language) => void;
  /**ppt对话中点击的media类型 */
  pptChatClickMediaType?: EMediaType;
  setPptChatClickMediaType: (payload: EMediaType | undefined) => void;
  convertTimeout: boolean;
  setConvertTimeout: (payload: boolean) => void;
  channelSearchSwitch?: boolean;
  setChannelSearchSwitch: (payload?: boolean) => void;
  /**对话是否禁用了联网开关 */
  switchRagDisabled?: boolean;
  setSwitchRagDisabled: (payload?: boolean) => void;
  writingSettings?: AdvanceConfigSettings;
  setWritingSettings: (payload: AdvanceConfigSettings) => void;
}

const useChatStore = create<
  ChatState,
  [['zustand/devtools', never], ['zustand/persist', Partial<ChatState>]]
>(
  devtools(
    persist(
      (set) => ({
        channel: {},
        setChannel: (payload: ChannelPayload) =>
          set(({ channel }) => ({ channel: isFunction(payload) ? payload(channel) : payload })),
        isOneChatChannel: undefined,
        setIsOneChatChannel: (payload?: boolean) => set(() => ({ isOneChatChannel: payload })),
        showOneChatGUI: false,
        setShowOneChatGUI: (payload: boolean) => set(() => ({ showOneChatGUI: payload })),
        sendOptions: {},
        setSendOptions: (payload: SendOptions) => set(() => ({ sendOptions: payload })),
        timeMap: {},
        setTimeMap: (payload: TimeMap | string) =>
          set(({ timeMap }) => {
            if (typeof payload === 'string') {
              delete timeMap[payload];
              return { timeMap: { ...timeMap } };
            } else {
              return { timeMap: { ...timeMap, ...payload } };
            }
          }),
        messages: [],
        setMessages: (payload: MessagesPayload) =>
          set(({ messages }) => ({ messages: isFunction(payload) ? payload(messages) : payload })),
        messagesReady: false,
        setMessagesReady: (payload: boolean) => set(() => ({ messagesReady: payload })),
        fileAnchor: {
          anchorId: '',
          pageNumber: 1,
        },
        setFileAnchor: (payload: FileAnchor) => set(() => ({ fileAnchor: payload })),
        localFileUpload: false,
        setLocalFileUpload: (payload: boolean) => set(() => ({ localFileUpload: payload })),
        pptSendType: null,
        setPptSendType: (payload: PPT_TYPE | null) => set(() => ({ pptSendType: payload })),
        selectionLanguage: {},
        setSelectionLanguage: (payload: Language) => set(() => ({ selectionLanguage: payload })),
        pptActiveCardId: '',
        setPptActiveCardId: (payload: string) => set(() => ({ pptActiveCardId: payload })),
        jumpSlides: false,
        setJumpSlides: (payload: boolean) => set(() => ({ jumpSlides: payload })),
        showStepBar: false,
        setShowStepBar: (payload: boolean) => set(() => ({ showStepBar: payload })),
        pptStep: 1,
        setPptStep: (payload: PPTStep) => set(() => ({ pptStep: payload })),
        pptEditedTime: 0,
        setPptEditedTime: (payload: number) => set(() => ({ pptEditedTime: payload })),
        description: undefined,
        setDescription: (payload: string) => set(() => ({ description: payload })),
        pptEnrichInfo: undefined,
        setPPTEnrichInfo: (payload: PPTEnrichInfo) => set(() => ({ pptEnrichInfo: payload })),
        editAction: undefined,
        setEditAction: (payload: string) => set(() => ({ editAction: payload })),
        lastPPTTemplateId: undefined,
        setLastPPTTemplateId: (payload: number) => set(() => ({ lastPPTTemplateId: payload })),
        pptSettings: undefined,
        setPptSettings: (payload: AdvanceConfigSettings) => set(() => ({ pptSettings: payload })),
        pptAudienceCustomize: [],
        setPptAudienceCustomize: (payload: AdvanceConfigSettingItem[]) =>
          set(() => ({ pptAudienceCustomize: payload })),
        pptSlidesCustomize: [],
        setPptSlidesCustomize: (payload: AdvanceConfigSettingItem[]) =>
          set(() => ({ pptSlidesCustomize: payload })),
        outputModel: '', // 这里不初始化，fetchUserInfo的时候会初始化
        setOutputModel: (payload: string) => set(() => ({ outputModel: payload })),
        // #region PDF提取图片
        setActiveImageId: (payload?: number) => set(() => ({ activeImageId: payload })),
        setImageChatWith: (payload?: ImageChatWithType) => set(() => ({ imageChatWith: payload })),
        setHiresImages: (payload?: HiresImage[]) => set(() => ({ hiresImages: payload })),
        // #endregion PDF提取图片
        ocrLanguage: DEFAULT_OCR_LANGUAGE,
        setOcrLanguage: (payload: Language) => set(() => ({ ocrLanguage: payload })),
        sendOcrLanguage: DEFAULT_OCR_LANGUAGE,
        setSendOcrLanguage: (payload: Language) => set(() => ({ sendOcrLanguage: payload })),
        setPptChatClickMediaType: (payload: EMediaType | undefined) =>
          set(() => ({ pptChatClickMediaType: payload })),
        convertTimeout: false,
        setConvertTimeout: (payload: boolean) => set(() => ({ convertTimeout: payload })),
        setChannelSearchSwitch: (payload?: boolean) =>
          set(() => ({ channelSearchSwitch: payload })),
        setSwitchRagDisabled: (payload?: boolean) => set(() => ({ switchRagDisabled: payload })),
        writingSettings: undefined,
        setWritingSettings: (payload: AdvanceConfigSettings) =>
          set(() => ({ writingSettings: payload })),
      }),
      {
        name: STORE_KEY.Chat,
        storage: createJSONStorage(() => localStorage),
        partialize: ({ timeMap, selectionLanguage, ocrLanguage }: ChatState) => ({
          timeMap,
          selectionLanguage,
          ocrLanguage,
        }),
      },
    ),
  ),
);

export default useChatStore;
